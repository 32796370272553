import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fa7e424"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "_card"
}
const _hoisted_2 = {
  key: 0,
  class: "_card_title"
}
const _hoisted_3 = { class: "_card_content" }
const _hoisted_4 = { class: "prompt_line" }
const _hoisted_5 = {
  key: 0,
  class: "alert"
}
const _hoisted_6 = { class: "prompt_line" }
const _hoisted_7 = {
  key: 1,
  class: "alert"
}
const _hoisted_8 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "_card_body",
              onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop","prevent"]))
            }, [
              ($props.title)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, " 手机登录 "))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _withDirectives(_createElementVNode("input", {
                    class: "prompt_input",
                    placeholder: "请输入手机号",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.phone) = $event))
                  }, null, 512), [
                    [_vModelText, $setup.phone]
                  ])
                ]),
                ($setup.phoneAlertSHow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, " 请输入正确的手机号 "))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("input", {
                    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.codeInput && $setup.codeInput(...args))),
                    class: "prompt_input_code",
                    placeholder: "请输入验证码"
                  }, null, 32),
                  _createElementVNode("div", {
                    class: _normalizeClass({send_code:true, off_cd:$setup.promptSMSCD <= 0}),
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.sendCode && $setup.sendCode(...args)))
                  }, _toDisplayString($setup.promptSMSCD > 0 ? `${$setup.promptSMSCD}s 后重试` : `发送验证码`), 3)
                ]),
                ($setup.codeAlertSHow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, " 请输入短信收到的四位验证码 "))
                  : _createCommentVNode("", true),
                ($props.type=='login')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "_link",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => {$setup.router.push('/contact-us');$setup.emit('cancel')})
                    }, "没有账号？联系我们注册"))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_8, [
                true
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "button",
                      onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($setup.emit('cancel')), ["stop","prevent"]))
                    }, " 取消 "))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "button",
                  onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => ($setup.handleConfirm && $setup.handleConfirm(...args)), ["stop","prevent"]))
                }, " 确定 ")
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}