import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81f47e2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "_card"
}
const _hoisted_2 = {
  key: 0,
  class: "_card_title"
}
const _hoisted_3 = { class: "_card_content" }
const _hoisted_4 = { class: "prompt_title" }
const _hoisted_5 = ["onChange", "type"]
const _hoisted_6 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "_card_body",
              onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop","prevent"]))
            }, [
              ($props.title)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.title), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.prompts, (p) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "prompt_line",
                    key: p
                  }, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(p), 1),
                    _createElementVNode("input", {
                      onChange: e=>$setup.handleInput(e, p),
                      class: "prompt_input",
                      type: p=='密码'?'password':'text'
                    }, null, 40, _hoisted_5)
                  ]))
                }), 128)),
                ($props.type=='login')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "_link",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => {$setup.router.push('/contact-us');$setup.emit('cancel')})
                    }, "没有账号？联系我们注册"))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "button",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($setup.handleConfirm && $setup.handleConfirm(...args)), ["stop","prevent"]))
                }, " 确定 "),
                true
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "button",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.emit('cancel')), ["stop","prevent"]))
                    }, " 取消 "))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}