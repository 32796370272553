
import { defineComponent, ref, onMounted, watch } from 'vue'
import scroll from '@/scroll'

export default defineComponent({
    name: "Nav",
    setup(){
        const appHeght = ref(0)
        const footerHeight = ref(0)

        const rendered = ref(1)

        onMounted(()=>{
            const appElem = document.querySelector('#app')?.getBoundingClientRect()
            if(appElem){
                appHeght.value = appElem.height
            }
            const footerElem = document.querySelector('#footer')?.getBoundingClientRect()
            if(footerElem){
                footerHeight.value = footerElem.height
            }
            rendered.value = 1
        })

        const getDocHeight = () => Math.max(document.documentElement.scrollHeight, appHeght.value)

        const getViewportHeight = () => Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        
        const getLineHeight = () => getDocHeight() - 300 - 640

        const getHeightPercents = () => Math.min(1, scroll.value/(getDocHeight() - getViewportHeight()- 440))

        return { 
            rendered,
            appHeght,
            getLineHeight,
            getHeightPercents,
            document,
            scroll
        }
    },
})
