import axios from 'axios';
import {backEndURL} from '@/siteConfig'

export const register = (userInfo:any) => {
    return new Promise<any>((resolve, reject) => {
        axios.post(`${backEndURL}/users/register`, userInfo)
        .then(res=>{
            if(res.data.status == 200){
                resolve(res.data.payload)
            }else{
                reject(res.data.message)
            }
        }).catch(err => {
            reject('注册失败，请稍后再试')
        })
    })
}