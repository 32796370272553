import {ref} from 'vue'
import currTab from '@/views/currTab'

export const scrollCount = ref(0)
export const scrollId = ref('')
export const scrollTo = (tab:string) => {
    scrollCount.value ++
    scrollId.value = encodeURIComponent(tab)
    currTab.value = tab
}
