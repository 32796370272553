import {ref} from 'vue'
import {authURL} from '@/siteConfig'
import axios from 'axios'
import {modal} from '@/modal'
import store from '@/store'

export const loginState = ref('' as any) 

export const SMSLogin = (data) => {
    return new Promise((resolve, reject) => {

        axios.post(`${authURL}/sms-login`, data)
        .then(res=>{
            if(res.data.status === 200){
                // loginState.value = res.data.payload
                store.commit('login', res.data.payload)
                resolve(0)
            }else{
                reject(`登录失败：${res.data.message}`)
            }
        }).catch(err=>{
            reject('登录失败，请重试')
        })
    })
}