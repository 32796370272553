
import {watch, ref} from 'vue'
import router from '@/router'
export default {
  props: {
    show: {
      default: false,
    },
    image: {
      default: '',
    },
    showCancel: {
      default: false
    },
    title:{
        default:''
    },
    prompts:{
        default:[]
    },
    type:{
        default:''
    }
  },
  setup(props, {emit}){
      const formData = ref<any>({})
      props.prompts.forEach(p=>{
          formData.value[p] = ''
      })
      const handleInput = (e:any, p:string) => {
          formData.value[p] = e.target.value
      }

      const handleConfirm = () => {
          emit('confirm', formData.value)
      }
    return {
        handleInput,
        handleConfirm,
      emit, console,
       router
    }
  }
}
