
import {defineComponent, onMounted, ref} from 'vue'
import { modal } from './modal'
import { scroll } from './scroll'
import {windowWidth} from '@/windowWidth'
import { layout } from './layout'
import Modal from "@/components/Modal.vue";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import lang from "@/lang"
import router from "@/router"

export default defineComponent({
  name: 'App',
  components: {
    Modal,
    Nav,
    Footer
  },
  setup(){

    if(process.env.NODE_ENV !== 'production'){
        window.alert = (msg:string)=>{
            console.warn("ALERT: " + msg)
        }
    }

    // watch scroll
    window.addEventListener('scroll', () => {
      scroll.value = document.documentElement.scrollTop
    })
    window.addEventListener('resize', ()=> windowWidth.value = window.innerWidth )

    // onMounted(()=>{
    //     router.push('/en')
    // })

    return {
      modal,
      scroll,layout,
      lang
    }
  }
})
