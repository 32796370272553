import {ref} from 'vue'
import axios from 'axios'
import {authURL} from '@/siteConfig'

export const promptSMSCD = ref(0)
export const phone = ref('')
export const code = ref('')

export const sendSMS = (phone_number: string) => {
    axios.post(`${authURL}/send-sms-code`,{phone_number})
}