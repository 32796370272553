import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import {pages}  from '@/siteConfig'
import {onMounted} from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/Home',
    component: () => import(`@/views/ViewProto.vue`),
    props:{
      headImage:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%A4%B4%E5%9B%BE/%E9%A6%96%E9%A1%B5.jpg',
      // headImage:'https://huamei2001.oss-cn-shenzhen.aliyuncs.com/hm2023/官网图片素材20230214/面朝大海.jpg',
    //   headImage:'https://oss.huamei2001.com/hm2022-1/首页/IMG_4638.JPG?versionId=CAEQOhiBgIC_iqLekhgiIGM0OWY1ZjM3OTUwZjQxMTJiMTE2ZmM3MmYxMjVmNjZk',
    //   headVideo:'https://oss.huamei2001.com/hm2022-1/home/koi.mp4?versionId=CAEQLhiBgMDh.dCn9RciIDE4ODA1MWU0M2E3NTQyMThiOWExZjcyYmVlOTNkNzYx',
      pageName:'home',
      name:'home',
      brightness:0.7,
    //   pageTitle:"改革开放",
    //   pageSubTitle:"是决定当代中国命运的关键一招",
    //   pageTitle:"<div>艰难的经历</div><div>都会变成美好回忆</div>",
    //   pageSubTitle:"告别雪山，回归生活日常，继续快乐奋斗！",
      pageTitle:"<div>尽管问 PK ChatGPT</div>",
      pageSubTitle:"定制大模型助力酒店提升经营业绩",
      pageHeadArticle:'https://huamei2001.com/article?id=3439',
      tabs:[],
    }
  },
  {
    path: '/brochure',
    name:'brochure',
    component: () => import(`@/views/Brochure.vue`),
    props:{
    }
  },
  {
    path: '/user-home',
    name:'userHome',
    component: () => import(`@/views/UserHome.vue`),
    props:{
    }
  },
  {
    path: '/en',
    name:'en',
    component: () => import(`@/views/En.vue`),
    props:{
    }
  },
  {
    path: '/user-asset',
    component: () => import(`@/views/UserAsset.vue`),
    props:{
    }
  },
  {
    path: '/article',
    component: () => import(`@/views/Article.vue`),
  },
  {
    path: '/contact-us',
    component: () => import(`@/views/Contact.vue`),
  },

  ...pages.map(page =>{
    return {
      path: `${page.link}/:tab?`,
      name:page.data,
      component: () => import(`@/views/ViewProto.vue`),
      props:{
        headImage:page.headImage,
        //@ts-ignore
        headVideo:page.headVideo,
        pageName:page.data,
        pageTitle:page.bigTitle || page.pageNameCN,
        pageHeadArticle:page.pageHeadArticle,
        titleColor:page.titleColor,
        shadowColor:page.shadowColor || '#33333388',
        pageSubTitle:page.pageSubTitle || '',
        brightness: page['brightness'] || 1,
        tabs:page.tabs,
      }
    }
  }),
  {
    path: "/:catchAll(.*)",
    redirect: '/',
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

router.afterEach(()=>{
    const blocks = Array.from(document.querySelectorAll('._page_block'))
})

export default router
