import { createStore } from 'vuex'
import {fetchImg} from '@/utils/fetchImg'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

import version from '@/version'

export interface VuexState {
    currLanguage:'cn' | 'en',
    defaultHeadImg: string,
    loginState:any,
    version:string
}

(()=>{
    // get old vuex data from localStorage, either encrypted or not
    let storedVuex
    try{
        storedVuex = JSON.parse(ls.get('vuex'))
    }catch(err){
        console.log('initialize store 1')
    }
    if(!storedVuex){
        try{
            storedVuex = JSON.parse(localStorage.vuex)
        }catch(err){
            console.log('initialize store 2')
        }
    }

    // compare versions
    if((!storedVuex) || storedVuex.version != version){
        console.log(`updated version ${version}`)
        localStorage.clear()
        return
    }else{
        console.log(`version ${version}`)
    }
    
    // compare time
    if((!storedVuex.loginState) || (!storedVuex.loginState.loginTime)){
        localStorage.clear()
        return
    }
    if(storedVuex.loginState.loginTime + 24 * 60 * 60 * 1000 < Date.now()){
        console.log('login expired')
        localStorage.clear()
        return
    }else{
        console.log('login valid')
    }
})()

const store = createStore({
  state: {
    currLanguage:'cn',
    defaultHeadImg: '',
    loginState:'',
    version
  },
  plugins: [
    createPersistedState({
        storage: {
          getItem: (key) => ls.get(key),
          setItem: (key, value) => ls.set(key, value),
          removeItem: (key) => ls.remove(key),
        },
      }),
  ],
  mutations: {
    languageCN:(state: VuexState)=>{
        state.currLanguage = 'cn'
    },
    languageEN:(state: VuexState)=>{
        state.currLanguage = 'en'
    },
    setDefaultHeadImg:(state: VuexState, url: string)=>{
        state.defaultHeadImg = url
    },
    login: (state: VuexState, loginData: any)=>{
        state.loginState = loginData
    },
    logout: (state: VuexState)=>{
        state.loginState = ''
    },
  },
  actions: {
  },
  modules: {
  }
})

fetchImg('https://oss.huamei2001.com/temp/lanterns.jpg')
.then(url=>{
  store.commit('setDefaultHeadImg', url)
})

export default store