
import { useRouter, useRoute } from 'vue-router';
import store from '@/store'
import { defineComponent, nextTick, onMounted, PropType, ref} from 'vue'
import {pages, PageItem}  from '@/siteConfig'
import currTab from '@/views/currTab'
import {windowWidth} from '@/windowWidth'

import Prompt from '@/components/Prompt.vue'
import {modal} from '@/modal'

import {loginState} from '@/loginState'

export default defineComponent({
  name: "Nav",
  props:{
    scroll: {
      type:Number as PropType<number>,
    },
  },
  components:{
      Prompt,
  },
  setup(){

    const route = useRoute()
    const router = useRouter()

    const showLogin = ref(false)
    const showRegister = ref(false)
    const confirmLogin = ()=> {
        showLogin.value = false
        modal.alert('用户名/密码错误，请重试！',()=>showLogin.value=true)
    }
    const confirmRegister = ()=> {
        showRegister.value = false
        modal.alert('注册申请已提交审核，请留意邮件！')
    }

    const goto = (p:PageItem | string) => {
      if(typeof(p)=='string'){
        router.push(p)
      }else{
        // router.push(p.link)
      }
    }

    const onMenuBtn = ref<{[key:string]:boolean}>({})
    const fixedTab = ref<string>('')

    onMounted(()=>{
        if(navigator.language.split('-')[0]!='zh'){
            const HMLang = localStorage.getItem('hm-lang')
            // alert(`HMLang, ${HMLang}`)
            if(HMLang=='zh'){
                return
            }
            // if(!document.referrer.includes('hm2001')){
            //     window.location.href = 'https://huamei2001.com/index-en.html'
            // }
        }
    })

    const onClickTab = (name:string, tab:string) => {
        if(name == 'Jinguanwen'){
            window.open('https://hotelmgmt.jinguanwen.com')
            return
        }
        currTab.value = tab
        router.push({name})
    }

    const loaded = ref(false)
    const fixAnimation = ref(false)

    onMounted(()=>{
        pages.filter(x=>x.nav).forEach(x=>{
            const tryAdd = setInterval(()=>{
                const navItem = document.querySelector(`[button_name='${x.data}']`)
                if(!navItem){
                    return
                }
                else{ 
                    const overBtn = ()=> { 
                        return
                    }
                    const outBtn = ()=> {
                        onMenuBtn.value[x.data]=false
                    }

                    navItem.addEventListener('mouseover', overBtn)
                    navItem.addEventListener('mouseout',outBtn)
                    clearTimeout(tryAdd)
                }
            }, 500)
        })
        setTimeout(()=>{
            loaded.value = true
            setTimeout(() => {fixAnimation.value = true},1500)
        },500)
    })

    
    return { 
        loginState,
        showLogin,
        showRegister,
        confirmLogin,
        confirmRegister,
        windowWidth,
        fixedTab,
        onMenuBtn,
        currTab,
        onClickTab,
        goto,
        window,
        route,
        pages,
        store,
        loaded,
        fixAnimation
    }
  },
})
