import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            scroll_enter:true,
            invisible: _ctx._direction=='' && !_ctx.switched,
            invisible_left: _ctx._direction=='left' && !_ctx.switched,
            invisible_right: _ctx._direction=='right' && !_ctx.switched,
            invisible_bottom: _ctx._direction=='bottom' && !_ctx.switched,
            invisible_top: _ctx._direction=='top' && !_ctx.switched,
        }),
    style: _normalizeStyle(`transition: all ${_ctx._duration}s ease-out`),
    ref: "pageBlock"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 6))
}