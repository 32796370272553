
import { useRouter, useRoute } from 'vue-router';
import store from '@/store'
import { defineComponent, ref, onMounted, nextTick, PropType } from 'vue'
import {pages, PageItem}  from '@/siteConfig'
import lang from '@/lang'
import {modal} from '@/modal'
import currTab from '@/views/currTab'
import {scrollTo} from '@/views/homeScroll'
import PromptSMS from '@/components/PromptSMS.vue'

import {loginState} from '@/loginState'
import {register, logIn} from '@/users'

import { SMSLogin } from '@/loginState'

export default defineComponent({
  name: "Nav",
  props:{
    scroll: {
      type:Number as PropType<number>,
    },
  },
  components:{
      PromptSMS,
  },
  setup(){

    const router = useRouter()
    const goto = (p:PageItem, t:string) => {
        if(p.pageNameCN=='为您服务'){
            router.push(p.link + '/' + t)
            nextTick(()=>{
                scrollTo(encodeURIComponent(t))
            })
        }else{
            currTab.value = t
            router.push(p.link)
        }

    }

    const onConfirmLogin = (data) => {
        SMSLogin(data).then(res=>{
            showLogin.value = false
            modal.alert(`欢迎，${store.state.loginState.userInfo.real_name}`)
        }).catch(err=>{
            showLogin.value = false
            modal.alert(err, ()=>{
                showLogin.value = true
            })
        })
    }

    onMounted(()=>{
        let params = (new URL(document.location.href)).searchParams;
        let lang = params.get("lang");
        console.log('lang', lang)
        if(lang == 'ct'){
            changeLang('cnt')
        }
    })

    const changeLang = (btn:string) => {
        if(btn == 'cnt'){
            if(lang.value.cn == 's'){
                lang.value.cn = 't'
            }else if(lang.value.cn == 't'){
                lang.value.cn = 's'
            }
        }
        if(btn == 'eng'){
            currTab.value = 'en'
            window.location.href = 'https://huamei2001.com/index-en.html'
            // modal.alert(`English version is coming up soon. Drop us an email at: nihao@huamei2001.com, and we will get back to you right away!`)
            // lang.value.eng = !lang.value.eng 
        }
    }


    const showLogin = ref(false)
    const showRegister = ref(false)
    // const confirmLogin = (data)=> {
    //     showLogin.value = false
    //     if( data['邮箱'].length == 0 ){
    //         modal.alert('请输入邮箱！', ()=>showLogin.value = true)
    //         return
    //     }
    //     if( data['密码'].length==0 ){
    //         modal.alert('请输入密码！', ()=>showLogin.value = true)
    //         return
    //     }
    //     const reqBody = {
    //         username:data['用户名'], email:data['邮箱'], phone:data['电话'], secret:data['密码']
    //     }
    //     logIn(reqBody).then(res=>{
    //         loginState.value = res.username
    //         modal.alert(`欢迎, ${res.username}`)
    //     }).catch(msg=>{
    //         modal.alert(msg, ()=>showLogin.value = true)
    //     })
    // }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const confirmRegister = (data)=> {
        showRegister.value = false
        if(data['用户名'].length == 0 || data['邮箱'].length == 0 || data['密码'].length==0){
            modal.alert('请输入完整的注册信息！', ()=>showRegister.value = true)
            return
        }
        if(!validateEmail(data['邮箱'])){
            modal.alert('请输入有效的邮箱！', ()=>showRegister.value = true)
            return
        }
        const reqBody = {
            username:data['用户名'], email:data['邮箱'], phone:data['电话'], secret:data['密码']
        }
        register(reqBody).then(res=>{
            modal.alert('注册成功！')
        }).catch(msg=>{
            modal.alert(msg)
        })
    }

    const handleClickLogin = () => {
        if(store.state.loginState != ''){
            modal.showCancel.value = true
            modal.query('确认退出吗？',()=>{
                // loginState.value =''
                store.commit('logout')
                modal.showCancel.value = false
                router.push('/')
            })
        }else{
            showLogin.value =true
        }
    }


    return { 
        lang,changeLang,
        goto,
        router,
        pages,
        store,
        currTab,
        showLogin,
        showRegister,
        confirmRegister,
        loginState,
        onConfirmLogin,
        handleClickLogin,

        window
    }
  },
})
