
import {watch, ref} from 'vue'
import router from '@/router'
import {promptSMSCD, phone, code, sendSMS} from './promptUtils'

export default {
  props: {
    show: {
      default: false,
    },
    image: {
      default: '',
    },
    showCancel: {
      default: false
    },
    title:{
        default:''
    },
    prompts:{
        default:[]
    },
    type:{
        default:''
    }
  },
  setup(props, {emit}){
        const codeInput = (e:any) => {
            code.value = e.target.value
        }

        const phoneAlertSHow = ref(false)
        const codeAlertSHow = ref(false)

        const sendCode = () => {
            codeAlertSHow.value = false
            if(promptSMSCD.value > 0){
                return
            }
            if(phone.value == '' || phone.value.length < 11) {
                phoneAlertSHow.value = true
                return
            }
            phoneAlertSHow.value = false
            promptSMSCD.value = 60
            sendSMS(phone.value)
            const CD = setInterval(()=>{
                promptSMSCD.value -= 1
                if(promptSMSCD.value == 0){
                    clearInterval(CD)
                }
            }, 1000)
        }
        const handleConfirm = () => {
            if(phone.value == '') {
                phoneAlertSHow.value = true
            }else{
                phoneAlertSHow.value = false
            }
            if(code.value == '') {
                codeAlertSHow.value = true
            }else{
                codeAlertSHow.value = false
            }
            if(phoneAlertSHow.value || codeAlertSHow.value){
                return
            }
            emit('confirm', {phone_number:phone.value, code:code.value})
        }

        return {
            phone, code,
            codeInput,
            phoneAlertSHow,
            codeAlertSHow,
            handleConfirm,

            emit, 
            console,
            router,

            promptSMSCD,
            sendCode
        }
  }
}
