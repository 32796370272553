import {ref, Ref} from 'vue'

export interface ModalType {
    title:string,
    content:string,
    image:string,
}
export interface ModalMade {
  valueHolder:Ref<ModalType>;
  show:Ref<boolean>;
  image:Ref<string>;
  showCancel:Ref<boolean>;
  confirm: Ref<()=>void>;
  cancel: Ref<()=>void>;
  alert:(str:string, cb?:()=>any, cb2?:() => any) => any;
  query:(str:string, cb?:()=>any, cb2?:() => any) => any;
  display:(str:string, img:string, cb1?:() => void, cb2?:() => void)=>void;
  loadOn:(str?:string)=>void;
  loadOff:()=>void;
}


export const ModalMaker = ():ModalMade => {
    const valueHolder =  ref<ModalType>({} as ModalType);
    const show = ref(false);
    const image = ref('');
    const showCancel = ref(false);
    const confirm = ref(() => {// console.log('confirm')
        })
    const cancel = ref(() => {// console.log('cancel')
        })

    const display = (str:string, img:string, cb1?:() => void, cb2?:() => void)=>{
            valueHolder.value.title = str;
            image.value = img;
            show.value = true;
            valueHolder.value.image = img;
            handleCb(cb1, cb2);
    }

    const alert = (str:string, cb1?:() => void, cb2?:() => void) => {
        valueHolder.value.title = str;
        show.value = true;
        handleCb(cb1, cb2);
    }
    const query = (str:string, cb1?:() => void, cb2?:() => void) => {
        valueHolder.value.title = str;
        show.value = true;
        showCancel.value = true;
        handleCb(cb1, cb2);
    }
    const loadOn = (str?:string) =>{
        valueHolder.value.title = str || "请稍候...";
        show.value = true;
    }
    const loadOff = () =>{
        show.value = false;
        image.value = ''
    }


    const handleCb = (cb1?:() => void, cb2?:() => void) => {
        if(cb1){
            confirm.value = ()=>{
                show.value = false;
                cb1()
                showCancel.value = false;
            } 
            if(cb2){
                cancel.value = ()=>{
                    show.value = false;
                    cb2()
                    showCancel.value = false;
                } 
            }else{
                cancel.value = () => {
                    show.value = false
                    showCancel.value = false;
                }
            }
        }else{
            confirm.value = () => {show.value = false;showCancel.value = false;}
            cancel.value = () => {show.value = false;showCancel.value = false;}
            image.value = ''
        }
    }

    return {
        valueHolder,
        show,
        showCancel,
        confirm,
        cancel,
        image,
        alert,
        query,
        display,
        loadOn,
        loadOff
    }
} 

export const modal =  ModalMaker()