
/* eslint-disable vue/no-unused-components */
import { defineComponent, watch, ref, onMounted, nextTick } from "vue";
import scroll from '@/scroll'

export default defineComponent({
    props: {
        _log:{default: false},
        _direction:{default: ''},
        _thresholdType:{default: 'vh'},
        _thresholdVal:{default: 0.1},
        _inactive:{default: false},
        _duration:{default: 0.5},
        _delay:{default:300}
    },
    setup(props){
        const pageBlock = ref<HTMLElement>()

        const switched = ref(props._inactive)

        const checkScroll = ()=>{
            if(switched.value) return
            const thisTop = pageBlock.value!.getBoundingClientRect().top
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
            if(props._thresholdType == 'vh' && thisTop < vh * (1-props._thresholdVal)){
                setTimeout(() => {
                    switched.value = true
                }, props._delay)
            }
            if(props._thresholdType == 'px' && thisTop < vh - props._thresholdVal){
                setTimeout(() => {
                    switched.value = true
                }, props._delay)
            }
        }
        
        onMounted(()=>{
            if(!pageBlock.value) return
            watch(()=>scroll.value, checkScroll)
        })

        return {
            pageBlock,
            switched,
        }
    }
});
