import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1129a7d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timage_wrap" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.imageSource || _ctx.store.state.defaultHeadImg,
      ref: "timage",
      alt: "",
      class: "_timage",
      style: _normalizeStyle(_ctx.imageStyle)
    }, null, 12, _hoisted_2)
  ]))
}