export const fetchImg = (src: string) => {
    return new Promise<string>((resolve, reject) => {
        fetch(src, {
            mode: 'no-cors',
            method: "get",
            headers: {
                 "Content-Type": "application/json"
            } 
         }) 
        .then(response => response.blob())
        .then(async images => {
            const blob_url = URL.createObjectURL(images)
            resolve(blob_url)
        })
        .catch(err => reject(err))
    })
}