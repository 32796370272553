import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-113bc159"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "decor1"
}
const _hoisted_3 = {
  key: 1,
  class: "decor2"
}
const _hoisted_4 = {
  key: 2,
  class: "decor3"
}
const _hoisted_5 = {
  key: 3,
  class: "decor4"
}
const _hoisted_6 = {
  key: 4,
  class: "decor5"
}
const _hoisted_7 = {
  key: 5,
  class: "decor6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "timage_wrap",
    style: _normalizeStyle(_ctx._style)
  }, [
    _createElementVNode("img", {
      src: _ctx.src || _ctx.src || _ctx.store.state.defaultHeadImg,
      ref: "timage",
      alt: "",
      class: "_timage",
      style: _normalizeStyle(_ctx._style)
    }, null, 12, _hoisted_1),
    (_ctx._direction=='right')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx._direction=='right')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx._direction=='right')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx._direction=='left')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx._direction=='left')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
      : _createCommentVNode("", true),
    (_ctx._direction=='left')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7))
      : _createCommentVNode("", true)
  ], 4))
}