import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import TImage from '@/components/TImage.vue'
import TImageDecor from '@/components/TImageDecor.vue'
import LineThrough from '@/components/LineThrough.vue'
import ScrollEnter from '@/components/ScrollEnter.vue'
import version from '@/version'


import axios from 'axios'


// if(
//     process.env.NODE_ENV === 'production'
//     && !window.location.href.match(/^https/)
// ){
//     window.location.href = 'https://huamei2001.com'
// }

//navigator.language.split('-')[0] = 'zh'


const timestamp = Date.now()

axios.post('https://backend.huamei2001.com/public/view-count', {timestamp})
.then(res=>console.log(res))
.catch(res=>console.log(res))

const app = createApp(App).use(store).use(router)

app.component('timg', TImage)
app.component('timgdecor', TImageDecor)
app.component('line-through', LineThrough)
app.component('scroll-enter', ScrollEnter)

app.mount('#app')
