
import { defineComponent, PropType, ref, onMounted, computed, watch } from 'vue'
import {fetchImg} from '@/utils/fetchImg' 
import store from '@/store'

export default defineComponent({
  name: "Nav",
  props:{
      src: {
          default:'',
          type:String as PropType<string>
      },
      _style: {
          default:'',
          type:String as PropType<string>
      },
      _direction: {default:'right'}
  },
  components:{

  },
  setup(props){
    const imageSource = ref('')
    
    fetchImg(props.src) 
    .then( blob_url => {
        imageSource.value = blob_url
    })

    return { 
        imageSource,
        store
    }
  },
})
